import React, { useState } from 'react';
import './App.css';

function App() {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://us-central1-databricks-ins-groupm-ca.cloudfunctions.net/quotationsDelete', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('File upload failed');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'downloaded_file';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file. Please try again.');
    }
  };

  return (
    <div className="App">
      <div className="author">Quotations CSV Helper tool made by Miguel Ajon. Contact miguel.ajon@groupm.com for issues.</div>
      <div className="centered-container">
        <input
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="file-input"
        />
        <label htmlFor="file-input" className="centered-button">
          Select File
        </label>
        {file && (
          <button onClick={handleUpload} className="centered-button">
            Upload
          </button>
        )}
      </div>
    </div>
  );
}

export default App;
